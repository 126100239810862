import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Space, Checkbox, Button } from "antd";
import { MdFilterListAlt } from "react-icons/md";
import { DropArea, MenuCheckbox, MenuBtn } from "../dropdown.style";
import { NavLink } from "react-router-dom";
import { getYears } from "../../../utils/api";

const YearDropdown = ({ setYearFilter, yearFilter }) => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    getYears(setYears);
  }, []);

  let items = [];
  let tempYears = years;
  tempYears = tempYears.sort((a, b) => a.sortingNumber - b.sortingNumber);

  for (let i = 0; i < tempYears.length; i++) {
    items.push({
      label: (
        <MenuCheckbox
          onClick={(e) => {
            // e?.stopPropagation();
            let key = `${tempYears[i].name},${tempYears[i].id}`;

            yearFilter.includes(key) ? setYearFilter([]) : setYearFilter([key]);
          }}>
          <Checkbox
            checked={yearFilter.includes(
              `${tempYears[i].name},${tempYears[i].id}`
            )}>
            {tempYears[i].name}
          </Checkbox>
        </MenuCheckbox>
      ),
      key: `${tempYears[i].name},${tempYears[i].id}`,
    });
  }

  const menu = (
    <>
      <Menu items={items} />
    </>
  );

  return (
    <>
      <DropArea className='yearDrop'>
        <Dropdown
          placement='bottom'
          arrow={{
            pointAtCenter: true,
          }}
          overlay={menu}
          trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Years
              <MdFilterListAlt />
            </Space>
          </a>
        </Dropdown>
      </DropArea>
    </>
  );
};

export default YearDropdown;
