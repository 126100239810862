import styled from "styled-components";

export const FilterContentArea = styled.div`
  .contentHead {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.5rem;
    background-color: rgba(36, 0, 255, 0.15);
    h2 {
      color: var(--MainColor);
      margin: 0.5rem 0;
    }
    .ant-dropdown-trigger {
      .ant-space {
        display: block;
        .dot {
          width: 8px;
          height: 8px;
          background-color: var(--MainColor);
          border-radius: 50%;
          margin-bottom: 0.125rem;
        }
      }
    }
  }
  .contentBody {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      & {
        justify-content: space-around;
      }
    }

    img {
      margin: 1.5rem 1.25rem 1rem;
      min-height: 200px;
      min-width: 200px;
      max-height: 200px;
      max-width: 200px;
      object-fit: contain;
    }
    .emptyDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 2rem;
      font-size: 2rem;
      font-weight: 700;
      color: rgba(36, 0, 255, 0.15);
    }
  }
`;
export const SubItem = styled.a`
  text-decoration: none;
  width: 100%;
  display: inline-block;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(36, 0, 255, 0.58);
  svg,
  img {
    width: 25px;
    height: 25px;
    color: var(--MainColor);
  }
  span:last-child {
    font-size: 1rem;
    color: var(--MainColor);
    padding-left: 0.75rem;
  }
`;
