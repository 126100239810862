import styled from "styled-components";

export const PatchesArea = styled.div`
  .actionContainer {
    @media (max-width: 768px) {
      flex-direction: column;
      div {
        margin-top: 15px;
      }
    }
  }
  .patchHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      display: block;
    }
    .dropDowns {
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        margin-bottom: 1rem;
      }

      @media (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;

        div {
          margin-bottom: 10px;
        }
      }
    }
    .searchInput {
      .ant-input-affix-wrapper {
        border: none;
        border-color: unset;
        box-shadow: none;
        padding: 0;
        width: 494px;
        height: 50px;
        input {
          background-color: rgba(36, 0, 255, 0.26);
          padding: 0 0.5rem;
          font-size: 1.5rem;
          color: rgba(36, 0, 255, 0.35);
          &::placeholder {
            color: rgba(36, 0, 255, 0.35);
          }
        }
        .ant-input-suffix {
          background-color: var(--MainColor);
          margin-left: 0;
          cursor: pointer;
          svg {
            width: 30px;
            height: 30px;
            margin: 0 0.5rem;
            color: var(--LightColor);
          }
        }
      }
      @media (max-width: 992px) {
        text-align: end;

        .ant-input-affix-wrapper {
          width: 100% !important;
        }
      }
    }
  }\

  .statContainer h2{
    font-size: 1.4em;
    text-align: center;
    border: 4px solid #443c76;
    border-radius: 7px;
    padding: 14px;
  }
  .patchContent {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    /* @media (min-width: 1200px) {
      justify-content: space-between;
    } */
    img {
      margin: 2.5rem 1.25rem 1rem 0.25rem;
      min-height: 200px;
      min-width: 200px;
      max-height: 200px;
      max-width: 200px;
      object-fit: contain;
    }
  }
`;
export const ClearBtn = styled.button`
  width: 150px;
  height: 50px;
  background-color: var(--MainColor);
  color: var(--LightColor);
  border: 1px solid var(--MainColor);
  border-radius: 0.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-left: 0.5rem;
`;
