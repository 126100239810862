import styled from "styled-components";

// Home-Page Open
// Hero-Section
export const HeroArea = styled.div`
  padding: 3.5rem 0;
  .heroRow {
    align-items: center;
    .heroInfo {
      .mainHeading {
        color: var(--MainColor);
        font-weight: 700;
      }
      .secondaryHeading {
        font-size: 2.25rem;
      }
    }
    .heroImage {
      img {
        width: 100%;
        /* border: 4px solid var(--MainColor);
        filter: drop-shadow(11px 12px 4px rgba(0, 0, 0, 0.25)); */
      }
    }
  }
`;
// Home-Content
export const HomeArea = styled.div`
  padding: 3rem 0;
  .homeContent {
    text-align: center;
    padding: 2rem 0;
    .contentHeading {
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 4rem;
        font-weight: 700;
        color: var(--MainColor);
        position: relative;
        cursor: pointer;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: var(--MainColor);
        }
      }
    }
    .contentInfo {
      width: 100%;
      max-width: 700px;
      margin: 1rem auto 3rem;
      p {
        color: var(--MainColor);
      }
    }

    .guideButton {
      margin-bottom: 40px;
    }
    /* Content-Footer */
    .contentFooter {
      a {
        text-decoration: none;
        width: 285px;
        height: 70px;
        // padding: 40px;
        background-color: var(--MainColor);
        border-radius: 2.625rem;
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 3.2;
        color: var(--LightColor);
      }
    }
  }
`;
export const Features = styled.div`
  padding: 2rem 5rem;
  background-color: #e5e5e5;
  @media (max-width: 575px) {
    padding: 1rem;
  }
  div:nth-child(4) {
    p {
      max-width: 100%;
    }
  }
`;
// Home-Page Close
