import React from "react";
import Header from "../../components/header/Header";
// import { Row, Col } from "react-bootstrap";
import { Row, Col } from "antd";
import { Features, HeroArea, HomeArea } from "./home.style";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import FeatureCard from "../../components/featureCard/FeatureCard";

const Home = () => {
  const data = [
    {
      image: "/images/laptop-icon.svg",
      heading: "Cross Platform",
      text: "Review NOAC Patches in your Smart Phone or your computer. We have a responsive design۔",
    },
    {
      image: "/images/search-icon.svg",
      heading: "Search for your Needs",
      text: "You can search for your needs on eBay or OA Lodge Store through the menu next to the Lodge name.",
    },
    {
      image: "/images/filter-icon.svg",
      heading: "Easy to Use Filtering",
      text: "You can filter by NOAC Year or by Lodge, or BOTH! Using our simple filtering and search you can find exactly what you are looking for",
    },
    {
      image: "/images/community-icon.svg",
      heading: "Community Driven",
      text: "You can help the guide grow by submitting missing patches. If you don't see a patch that should be there, just send us the information including a picture of the patch and we will get it added. Your contribution can help other collectors identify needs.",
    },
    {
      image: "/images/feedback-icon.svg",
      heading: "Open to Feedback",
      text: "Tell us if there is an error and we will get it fixed. Having a dynamic guide allows us to fix any error and come up and have the update as soon as we make the change.",
    },
    {
      image: "/images/feature-export-icon.svg",
      heading: "Export to PDF",
      text: "Do you want a PDF of the guide for offline use? Just filter by the Lodge or Year click export and we will create a PDF guide for you to download and use.",
    },
  ];
  return (
    <>
      <Header />
      <div style={{ padding: "0 1.75rem" }}>
        <HeroArea>
          <Row className='heroRow'>
            <Col lg={12}>
              <div className='heroInfo'>
                <h1 className='mainHeading'>
                  Welcome to NOAC Patch Guide <br />
                  <span className='secondaryHeading'>
                    The virtual guide for all things NOAC.
                  </span>
                </h1>
              </div>
            </Col>
            <Col lg={12}>
              <div className='heroImage'>
                <video
                  width='100%'
                  height='100%'
                  autoplay='autoplay'
                  muted
                  controls>
                  <source src='/promoVideo.mp4' type='video/mp4' />
                </video>
              </div>
            </Col>
          </Row>
        </HeroArea>

        <HomeArea>
          <div className='homeContent'>
            <div className='contentFooter guideButton'>
              <NavLink to='patches'>Enter Guide</NavLink>
            </div>
            <div style={{ margin: "1rem auto 1rem" }} className='contentInfo'>
              <p style={{ margin: 0 }}>
                NOAC is a trademark of the Boy Scouts of America, used with
                permission. All references to NOAC and any related content are
                used in compliance with the Boy Scouts of America’s guidelines.
                The NOAC Patch Guide is an independent resource and is not
                officially affiliated with or endorsed by the Boy
                Scouts of America.
              </p>
            </div>
            <div className='contentHeading'>
              <h2>Features</h2>
            </div>
            <div className='contentInfo'>
              <p>
                NOAC Patch Guide provides many features that help you review
                patches that are related to the National Order of the Arrow
                Conference. Here are some of the best features:
              </p>
            </div>
          </div>
          <Features>
            <Row gutter={[80, 22]}>
              {data.map((item) => (
                <Col xl={8} lg={12} xs={24}>
                  <FeatureCard data={item} />
                </Col>
              ))}
            </Row>
          </Features>
        </HomeArea>
      </div>
      <Footer />
    </>
  );
};

export default Home;
