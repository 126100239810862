import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Upload,
  Checkbox,
  Row,
  Col,
  message,
} from 'antd'
import Header from '../../components/header/Header'
import { SubmitArea } from '../login/index.styled'
import Footer from '../../components/footer/Footer'
import { getLodges, getYears, addPatches } from '../../utils/api'
import axios from 'axios'
import ImageGuidelineModal from '../../components/imageGuideline'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { useAuthValue } from '../../AuthContext'
import { updateUser } from '../../utils/api'
import { Link } from 'react-router-dom'

function parseQuery(queryString) {
  var query = {}
  var pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}
const SignUpScreen = () => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { Option } = Select
  const { TextArea } = Input
  const navigate = useNavigate()
  const { currentUser } = useAuthValue()
  if (currentUser) navigate('/')
  const reset = (docId) => {
    axios.get(
      `https://us-central1-noac-patch-guide.cloudfunctions.net/default/patchImages/${docId}`
    )
    form.resetFields()
  }

  const handleFinish = async (values) => {
    setLoading(true)
    await auth
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(async (res) => {
        await res.user.updateProfile({
          displayName: values.name,
        })
        updateUser({
          uid: res.user.uid,
          name: values.name,
          email: values.email,
        })
        navigate('/')
      })
      .catch((err) => message.error(err.message))

    setLoading(false)
  }

  return (
    <>
      <Header />
      <div style={{ padding: '0 1.5rem' }}>
        <SubmitArea>
          <ImageGuidelineModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
          <h2>Create your NOAC Patch Guide account</h2>
          <h5 className='mb-5'>Track your needs and collection</h5>

          <Row justify='center' style={{ height: '66vh' }}>
            <Col md={10}>
              <Form
                name='contactForm'
                layout='vertical'
                initialValues={{
                  remember: true,
                }}
                onFinish={handleFinish}
                form={form}
              >
                <Form.Item
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your name!',
                    },
                  ]}
                >
                  <Input className='nameInput' />
                </Form.Item>

                <Form.Item
                  label='Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input className='nameInput' />
                </Form.Item>

                <Form.Item
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input className='nameInput' type='password' />
                </Form.Item>

                <p className='my-4'>
                  <b>
                    By clicking 'Create account', you agree to the
                    <Link to='/about'> Terms of Use</Link> and you acknowledge
                    that you have read our{' '}
                    <Link to='/about'>Privacy Policy</Link>. You further
                    acknowledge that NOAC Patch Guide may use your email address
                    for communication and updates.
                  </b>
                </p>
                {/* <Checkbox required> */}
                {/* </Checkbox> */}
                <div className='submitBtn'>
                  <Button htmlType='submit' loading={loading}>
                    Create Account
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </SubmitArea>
      </div>
      <Footer />
    </>
  )
}

export default SignUpScreen
