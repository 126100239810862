import React from "react";
import { LodgeCardArea } from "./lodgeCard.style";

const LodgeCard = (props) => {
  const data = props.data;
  return (
    <LodgeCardArea>
      <img src={`/images/${data.image}`} alt='click here' />
      <p>Patch Name</p>
      <p>{data.text}</p>
    </LodgeCardArea>
  );
};

export default LodgeCard;
