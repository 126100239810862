import React, { useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Head } from './header.style'
import { NavLink } from 'react-router-dom'
import { useAuthValue } from '../../AuthContext'
import { Dropdown, Menu, Space, Switch } from 'antd'
import { DownOutlined, SmileOutlined, LogoutOutlined } from '@ant-design/icons'
import { auth, getToken } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { updateToken, getCollections, updateCollections } from '../../utils/api'
const Header = () => {
  const { currentUser, setCurrentUser } = useAuthValue()
  const [userLists, setUserLists] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {
    const fetchKey = async () => {
      let tok = await getToken(currentUser)
      console.log('tok', tok)
      if (tok) updateToken(tok, currentUser.uid)
    }
    if (currentUser) fetchKey()
  }, [currentUser])

  useEffect(() => {
    if (currentUser) {
      if (currentUser.userList) setUserLists(currentUser.userList)
      else getCollections(currentUser.uid, setUserLists)
    }
  }, [currentUser])
  

  let allMenu = [
    {
      key: '0',
      label: (
        <div
          style={{
            borderBottom: '1px solid black',
            margin: '5px 20px',
            paddingBottom: '10px',
          }}
        >
          <h5 className='text-center font-bold'>Your Lists</h5>
          <a
            rel='noopener noreferrer'
            href='#'
            onClick={() => {
              navigate('/needs')
            }}
            style={{ color: 'black' }}
            className='mb-4'
          >
            Needs
          </a>
          <br />
          <a
            rel='noopener noreferrer'
            href='#'
            onClick={() => {
              navigate('/collections')
            }}
            style={{ color: 'black' }}
          >
            Collections
          </a>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div
          style={{
            borderBottom: '1px solid black',
            margin: '5px 20px',
            paddingBottom: '10px',
          }}
        >
          <h5 className='text-center font-bold'>Notifications</h5>
          <div className='d-flex justify-content-between'>
            <a
              rel='noopener noreferrer'
              href='#'
              onClick={() => {
                navigate('/needs')
              }}
              style={{ color: 'black' }}
              className='mb-2'
            >
              Daily Email
            </a>
            <Switch defaultChecked />
          </div>
          <div className='d-flex justify-content-between'>
            <a
              rel='noopener noreferrer'
              href='#'
              onClick={() => {
                navigate('/needs')
              }}
              style={{ color: 'black' }}
              className='mb-2'
            >
              Browser Push
            </a>
            <Switch defaultChecked />
          </div>
        </div>
      ),
    },
    {
      key: '4',
      label: (
        <h5 style={{ margin: '5px 20px' }}>
          <a
            rel='noopener noreferrer'
            href='#'
            onClick={() => {
              auth.signOut()
            }}
            style={{ color: 'black' }}
            className='d-flex align-items-center'
          >
            <LogoutOutlined /> &nbsp; Logout
          </a>
        </h5>
      ),
    },
  ]
  if (userLists?.lodgeNotifications?.length > 0) {
    allMenu.splice(2, 0, {
      key: 2,
      label: (
        <div
          style={{
            borderBottom: '1px solid black',
            margin: '5px 20px',
            paddingBottom: '10px',
          }}
        >
          <h5 className='text-center font-bold'>Subscriptions</h5>
          {userLists?.lodgeNotifications.map((item, index) => {
            return (
              <a
                rel='noopener noreferrer'
                href='#'
                style={{ color: 'black' }}
                onClick={() => {
                  const finalObj = {
                    ...userLists,
                    lodgeNotifications: userLists.lodgeNotifications.filter(
                      (ite) => !ite.includes(item.split(',')[0])
                    ),
                  }
                  setUserLists(finalObj)
                  updateCollections(currentUser.uid, finalObj)
                  setCurrentUser({ ...currentUser, userList: finalObj })
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <p className='mb-2'> {item.split(',')[2]}</p>
                  <p className='mb-2'>X</p>
                </div>
              </a>
            )
          })}

          {userLists?.yearNotifications?.length > 0
            ? userLists?.yearNotifications.map((item, index) => {
                return (
                  <a
                    rel='noopener noreferrer'
                    href='#'
                    style={{ color: 'black' }}
                    onClick={() => {
                      const finalObj = {
                        ...userLists,
                        yearNotifications: userLists.yearNotifications.filter(
                          (ite) => !ite.includes(item.split(',')[0])
                        ),
                      }
                      setUserLists(finalObj)
                      updateCollections(currentUser.uid, finalObj)
                      setCurrentUser({ ...currentUser, userList: finalObj })
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p className='mb-2'> Year {item.split(',')[0]}</p>
                      <p className='mb-2'>X</p>
                    </div>
                  </a>
                )
              })
            : ''}
        </div>
      ),
    })
  }

  // if (userLists?.yearNotifications?.length > 0) {

  // }
  const menu = <Menu items={allMenu} />
  return (
    <Head>
      <Navbar variant='light' expand='lg'>
        <Navbar.Brand className='mainHeading' href='#'>
          <NavLink to='/'>
            <img src='/images/Logo.png' className='noacLogo' />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='navLinks'>
            <NavLink exact to='/'>
              <p>Home</p>
            </NavLink>
            <NavLink to='/patches'>
              <p>View Guide</p>
            </NavLink>
            <NavLink to='/submitPatch'>
              <p>Submit a Patch</p>
            </NavLink>
            <NavLink to='/supporters'>
              <p className='supporterBtn'>Supporters</p>
            </NavLink>
            <NavLink to='/contact'>
              <p>Contact Us</p>
            </NavLink>
            <NavLink to='/about'>
              <p className='aboutUsBtn'>About Us</p>
            </NavLink>
            {currentUser ? (
              <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Hi, {currentUser.displayName}!
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            ) : (
              <>
                <NavLink to='/login'>
                  <p>Login / Sign Up</p>
                </NavLink>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Head>
  )
}

export default Header
