import React, { useState, useEffect, useMemo, createRef } from 'react'
import { PatchesArea } from './index.style'
import {
  getPatches,
  getFilterdLodges,
  getLodges,
  getYears,
} from '../../utils/api'
import FilterContent from '../../components/printPdfContent/PrintFilterContent'
import YearDropdown from '../../components/dropdowns/yearDropdown/YearDropdown'

function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1])
    }
  }
  console.log('Query variable %s not found', variable)
}

const Patches = () => {
  const [patches, setPatches] = useState([])
  const [yearFilter, setYearFilter] = useState([])
  const [sortFilter, setSortFilter] = useState('')

  const [lodges, setLodges] = useState([])
  const [years, setYears] = useState([])
  const [selectedYear, setSelectedYear] = useState({})

  const ref = createRef()

  useEffect(() => {
    getLodges(setLodges)
    getYears(setYears)
  }, [])

  useEffect(() => {
    if (years.length > 0) {
      getPatches(setPatches, years)
    }
  }, [years])

  function addPageNumbers() {
    var totalPages = Math.ceil(document.body.scrollHeight / 842) //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    for (var i = 1; i <= totalPages; i++) {
      var pageNumberDiv = document.createElement('div')
      var pageNumber = document.createTextNode(
        'Page ' + i + ' of ' + totalPages
      )
      pageNumberDiv.style.position = 'absolute'
      pageNumberDiv.style.top = 'calc((' + i + ' * (297mm - 1.75px)) - 40px)' //297mm A4 pageheight; 0,5px unknown needed necessary correction value; additional wanted 40px margin from bottom(own element height included)
      pageNumberDiv.style.height = '16px'
      pageNumberDiv.style.fontWeight = 'bold'
      pageNumberDiv.style.fontSize = '12px'
      pageNumberDiv.classList.add('pageNumber')
      pageNumberDiv.appendChild(pageNumber)

      document.getElementById('contentPage').appendChild(pageNumberDiv)

      pageNumberDiv.style.left =
        'calc(100% - (' + pageNumberDiv.offsetWidth + 'px + 20px))'
    }
  }

  useEffect(() => {
    setSortFilter(getQueryVariable('sortFilter'))
    setYearFilter([getQueryVariable('selectedYear')])
  }, [])

  useEffect(() => {
    if (years.length > 0) {
      setSelectedYear(
        years.find((item) => item.id == getQueryVariable('selectedYear'))
      )
    }
  }, [years])

  useEffect(() => {
    document.querySelectorAll('.filterCont').forEach((el, index) => {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = el.getBoundingClientRect(),
        top = elemRect.height + elemRect.top,
        offset = top - bodyRect.top
      let page = index + 1
      let pageHeight = page * 1000

      if (offset > 1000) el.classList.add('pagebreak')
    })
  })

  const renderPatches = () => {
    let tempPatches = patches

    tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber)

    if (yearFilter.length > 0) {
      tempPatches = tempPatches.filter((item) => {
        return yearFilter.find((year) => item.year == year)
      })
    }

    var tempInd = 0
    let tempLodges = lodges

    tempLodges = tempLodges.sort((a, b) => {
      if (a.sortingNumber === '') return 1
      if (b.sortingNumber === '') return -1
      return a.sortingNumber - b.sortingNumber
    })

    var pdfArray = []
    let pageBreak = 1

    var results = tempLodges.map((filtered, index) => {
      if (tempPatches.filter((item) => item.lodge == filtered.id).length > 0) {
        tempInd += 1
        let returnContent = (
          <div className={'filterCont'}>
            <FilterContent
              key={tempInd}
              index={tempInd}
              patches={tempPatches.filter((item) => item.lodge == filtered.id)}
              heading={filtered.name}
              sortNumber={filtered.sortingNumber}
              yearFilter={yearFilter}
            />
          </div>
        )
        if (pageBreak == 3) pageBreak = 0

        pageBreak += 1

        return returnContent
      } else return false
    })
    results = results.filter((item) => item)

    return [results, pdfArray]
  }

  return (
    <>
      <div
        style={{ padding: '0rem 1rem', minHeight: '100vh', paddingTop: '0' }}
      >
        <PatchesArea>
          <img
            style={{ width: '100%', height: '100vh' }}
            src='/NOACPatchGuideTitlePage.jpg'
          />
          <h2 className='mainTitle'>NOAC {selectedYear?.name}</h2>

          <div ref={ref}>{renderPatches()[0]}</div>
          {/* <div className='divFooter'>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>
              NOAC {selectedYear?.name} Patches
            </p>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>
              noacpatchguide.com
            </p>
            <div style={{ opacity: '0' }}>noacpatchguide.com</div>
          </div> */}
          {/* <div id='contentPage'></div> */}
        </PatchesArea>
      </div>
    </>
  )
}

export default Patches
