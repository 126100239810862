import React, { useState, useEffect, useMemo, createRef } from "react";
import { BiUpArrowCircle } from "react-icons/bi";
import {
  message,
  Upload,
  BackTop,
  Button,
  Input,
  Image as Img,
  Modal,
} from "antd";
import moment from "moment";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { ClearBtn, PatchesArea } from "./patches.style";
import LodgeDropdown from "../../components/dropdowns/lodgeDropdown/LodgeDropdown";
import YearDropdown from "../../components/dropdowns/yearDropdown/YearDropdown";
import SortDropdown from "../../components/dropdowns/sortDropdown/SortDropdown";
import { TbSearch } from "react-icons/tb";
import {
  getPatches,
  getFilterdLodges,
  getLodges,
  getYears,
} from "../../utils/api";
import FilterContent from "../../components/filterContent/FilterContent";
import PdfFilterContent from "../../components/PdfFilterContent";
import { FilterArea, ExportArea } from "./filter.style";
import { Oval } from "react-loader-spinner";
import LazyLoad from "react-lazyload";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  ShoppingCartOutlined,
  HeartOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  HeartFilled,
  BellOutlined,
  BellFilled,
} from "@ant-design/icons";
import { Steps } from "intro.js-react";
import { useAuthValue } from "../../AuthContext";
import { getCollections, updateCollections } from "../../utils/api";
import stepsData from "./tourSteps.json";
import "intro.js/introjs.css";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
  },
});

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

const Patches = () => {
  const [patches, setPatches] = useState([]);
  const [lodgeFilter, setLodgeFilter] = useState([]);
  const [yearFilter, setYearFilter] = useState([]);
  const [sortFilter, setSortFilter] = useState("");
  const [yearState, setYearState] = useState({});
  const [introToggle, setIntroToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchVal, setSearchVal] = useState(null);
  const [lodges, setLodges] = useState([]);
  const [years, setYears] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [generatePdf, setGeneratePdf] = useState(null);
  const { currentUser, setCurrentUser } = useAuthValue();

  const ref = createRef();

  useEffect(() => {
    getLodges(setLodges);
    getYears(setYears);
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.userList) setUserLists(currentUser.userList);
      else getCollections(currentUser.uid, setUserLists);
    }
  }, [currentUser]);

  useEffect(() => {
    if (patches.length > 0) {
      setIsLoading(false);
    }
  }, [patches]);

  useEffect(() => {
    if (years.length > 0) {
      if (getQueryVariable("year")) {
        let foundYear = years.find(
          (item) => item.name === getQueryVariable("year")
        );
        if (foundYear) setYearFilter([`${foundYear.name},${foundYear.id}`]);
      } else {
        let temp = years.reverse()[0];
        let defaultYear = years.find((item) => item.defaultYear);
        if (defaultYear) {
          setYearFilter([`${defaultYear.name},${defaultYear.id}`]);
        } else {
          setYearFilter([`${temp.name},${temp.id}`]);
        }
      }

      getPatches(setPatches, years);
      setIsLoading(false);
    }
  }, [years]);

  useEffect(() => {
    if (yearFilter.length > 0) {
      if (!localStorage.getItem("tourDisabled")) {
        setIntroToggle(true);
      }

      let tempYearFilters = yearFilter.map((filtered) => {
        const ID = filtered.split(",")[1];

        return ID;
      });

      const tempLodges = lodges.filter((item) => {
        return tempYearFilters.find((year) => item.years?.includes(year));
      });

      let tempPatches = patches.filter((item) => {
        return (
          tempYearFilters.find((year) => item.year == year) &&
          tempLodges.find((lodge) => item.lodge == lodge.id)
        );
      });

      setYearState({
        lodgesAttended: tempLodges.length,
        patchesAttended: tempPatches.length,
      });
    }

    setPdfBody(
      <Document style={{ width: "100%" }}>
        <Page size='A4' style={styles.page} wrap={true}>
          <View style={{ position: "relative", width: "100%", height: "100%" }}>
            <Image src={"/NOACPatchGuideTitlePage.jpg"} />
          </View>
          {renderPatches()[1]}
          <View
            fixed
            style={{
              bottom: 0,
              position: "absolute",
              margin: 15,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <Text style={{ fontSize: 12 }}>NOAC 2022 Patches</Text>
            <Text style={{ fontSize: 12 }}>noacpatchguide.com</Text>
            <Text
              style={{ fontSize: 12, marginRight: 25 }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    );
  }, [yearFilter, lodgeFilter, lodges, patches]);

  const renderPatches = () => {
    let tempPatches = patches;

    tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber);

    if (searchVal) {
      tempPatches = tempPatches.filter((item) => {
        let lodge = lodges.find((lod) => lod.id == item.lodge);
        return (
          item?.comment?.toLowerCase().includes(searchVal.toLowerCase()) ||
          lodge?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
          lodge?.sortingNumber
            ?.toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          lodge?.sortingNumber == searchVal
        );
      });
    }

    if (yearFilter.length > 0) {
      let tempYearFilters = yearFilter.map((filtered) => {
        const ID = filtered.split(",")[1];

        return ID;
      });

      tempPatches = tempPatches.filter((item) => {
        return tempYearFilters.find((year) => item.year == year);
      });
    }
    // if (lodgeFilter.length > 0) {
    //   let tempLodges = lodgeFilter;

    //   if (sortFilter == "name")
    //     tempLodges = tempLodges.sort((a, b) => {
    //       const NameOne = a.split(",")[0];
    //       const NameTwo = b.split(",")[0];

    //       return NameOne.localeCompare(NameTwo);
    //     });
    //   else
    //     tempLodges = tempLodges.sort((a, b) => {
    //       const sortNumberOne = a.split(",")[2];
    //       const sortNumberTwo = b.split(",")[2];

    //       return sortNumberOne - sortNumberTwo;
    //     });

    //   var pdfArray = [];

    //   let results = tempLodges.map((filtered, index) => {
    //     const Name = filtered.split(",")[0];
    //     const ID = filtered.split(",")[1];
    //     const sortNumber = filtered.split(",")[2];
    //     const lodgeWebsiteLink = filtered.split(",")[3];

    //     pdfArray.push(
    //       <PdfFilterContent
    //         key={index}
    //         index={index}
    //         patches={tempPatches.filter((item) => item.lodge == ID)}
    //         heading={Name}
    //         sortNumber={sortNumber}
    //         yearFilter={yearFilter}
    //       />
    //     );
    //     return (
    //       <FilterContent
    //         key={index}
    //         index={index}
    //         patches={tempPatches.filter((item) => item.lodge == ID)}
    //         heading={Name}
    //         lodgeWebsiteLink={lodgeWebsiteLink}
    //         sortNumber={sortNumber}
    //         yearFilter={yearFilter}
    //       />
    //     );
    //   });

    //   return [results, pdfArray];
    // } else {
    var tempInd = 0;
    let tempLodges = lodges;
    if (sortFilter == "name") {
      tempLodges = tempLodges.sort((a, b) => a.name.localeCompare(b.name));
      tempLodges = [
        ...tempLodges.filter((item) => item.sortingNumber),
        ...tempLodges.filter((item) => !item.sortingNumber),
      ];
    } else {
      let tempNonNum = tempLodges.filter((item) => !item.sortingNumber);
      tempNonNum = tempNonNum.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      let tempExist = tempLodges.filter((item) => item.sortingNumber);
      tempLodges = tempExist.sort((a, b) => {
        if (a.sortingNumber === "") return 1;
        if (b.sortingNumber === "") return -1;
        return a.sortingNumber - b.sortingNumber;
      });
      tempLodges = [...tempLodges, ...tempNonNum];
    }
    var pdfArray = [];
    var results = tempLodges.map((filtered, index) => {
      if (tempPatches.filter((item) => item.lodge == filtered.id).length > 0) {
        pdfArray.push(
          <PdfFilterContent
            key={tempInd}
            index={tempInd}
            patches={tempPatches.filter((item) => item.lodge == filtered.id)}
            heading={filtered.name}
            sortNumber={filtered.sortingNumber}
            yearFilter={yearFilter}
          />
        );

        tempInd += 1;
        return (
          <FilterContent
            key={tempInd}
            index={tempInd}
            patches={tempPatches.filter((item) => item.lodge == filtered.id)}
            heading={filtered.name}
            lodgeId={filtered.id}
            sortNumber={filtered.sortingNumber}
            lodgeWebsiteLink={filtered.lodgeWebsiteLink}
            yearFilter={yearFilter}
            userLists={userLists}
            setUserLists={setUserLists}
          />
        );
      } else return false;
    });
    results = results.filter((item) => item);

    return [results, pdfArray];
    // }
  };

  let tempPatches = patches;

  if (searchVal) {
    tempPatches = tempPatches.filter((item) => {
      let lodge = lodges.find((lod) => lod.id == item.lodge);
      return (
        lodge?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
        lodge?.sortingNumber?.toLowerCase().includes(searchVal.toLowerCase()) ||
        lodge?.sortingNumber == searchVal
      );
    });
  }

  tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber);
  return (
    <>
      <Header />
      {patches.length > 0 ? (
        <Steps
          enabled={introToggle}
          steps={stepsData}
          initialStep={0}
          onExit={() => {
            setIntroToggle(false);
          }}
        />
      ) : (
        ""
      )}

      <div style={{ padding: "4rem 1.5rem", minHeight: "90vh" }}>
        <PatchesArea>
          <div className='patchHead pb-4'>
            <div className='dropDowns'>
              {/* <LodgeDropdown
                lodgeFilter={lodgeFilter}
                setLodgeFilter={setLodgeFilter}
              /> */}
              <YearDropdown
                yearFilter={yearFilter}
                setYearFilter={setYearFilter}
              />
              {yearFilter.length > 0 || lodgeFilter.length > 0 ? (
                <SortDropdown
                  sortFilter={sortFilter}
                  setSortFilter={setSortFilter}
                />
              ) : (
                ""
              )}

              {yearFilter.length > 0 || lodgeFilter.length > 0 ? (
                <ClearBtn
                  className='clearBtn'
                  onClick={() => {
                    setYearFilter([]);
                    setLodgeFilter([]);
                  }}>
                  Clear
                </ClearBtn>
              ) : (
                ""
              )}
            </div>
            {introToggle && patches.length > 0 ? (
              <div
                style={{
                  position: "fixed",
                  zIndex: "999999999",
                  right: "20px",
                  top: "20px",
                }}>
                <ClearBtn
                  className='clearBtn'
                  style={{ width: "auto" }}
                  onClick={() => {
                    setIntroToggle(false);
                    localStorage.setItem("tourDisabled", "true");
                  }}>
                  Do Not Show Again
                </ClearBtn>
              </div>
            ) : (
              ""
            )}

            <div className='actionContainer d-flex align-items-center gneneratePdf'>
              {yearFilter.length > 0 || lodgeFilter.length > 0 ? (
                generatePdf ? (
                  <ExportArea>
                    <PDFDownloadLink
                      fileName='NOAC 2022 PATCH Guide'
                      document={pdfBody}>
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <button>Loading Document</button>
                        ) : (
                          <button>Download is Ready</button>
                        )
                      }
                    </PDFDownloadLink>
                  </ExportArea>
                ) : (
                  <ExportArea
                    onClick={() => {
                      if (lodgeFilter.length == 0 && yearFilter.length > 0) {
                        let temp = yearFilter[0].split(",");

                        let foundYear = years.find(
                          (item) => item.id === temp[1]
                        );
                        window.open(foundYear.pdfLink, "_blank");
                      } else {
                        setGeneratePdf(true);
                        message.success(
                          "The PDF is going to take a few seconds to load"
                        );
                      }
                    }}>
                    <button>Generate PDF</button>
                  </ExportArea>
                )
              ) : (
                ""
              )}

              {yearFilter.length > 0 && (
                <div className='searchInput ' style={{ marginLeft: "15px" }}>
                  <Input
                    placeholder='Search'
                    onChange={(e) => setSearchVal(e.target.value)}
                    suffix={<TbSearch />}
                  />
                </div>
              )}
            </div>
          </div>
          {lodgeFilter.length > 0 || yearFilter.length > 0 ? (
            ""
          ) : (
            <h4
              style={{
                textAlign: "center",
                fontSize: "3em",
                fontWeight: "bold",
              }}>
              To get started select the Year above
            </h4>
          )}
          <div ref={ref}>
            {lodgeFilter.length > 0 || yearFilter.length > 0 ? (
              <FilterArea>
                {yearFilter.length > 0 ? (
                  <div className='d-flex align-items-center flex-wrap'>
                    <div className='filterHead'>
                      <h2 style={{ display: "flex", alignItems: "center" }}>
                        NOAC{" "}
                        {yearFilter.map(
                          (item, index) => `${item.split(",")[0]}`
                        )}{" "}
                        {currentUser ? (
                          userLists.yearNotifications?.find((item) =>
                            item.includes(yearFilter[0].split(",")[0])
                          ) ? (
                            <BellFilled
                              style={{
                                fontSize: "0.8em",
                                display: "inline-block",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const finalObj = {
                                  ...userLists,
                                  yearNotifications: userLists.yearNotifications
                                    ? userLists.yearNotifications.filter(
                                        (item) =>
                                          !item.includes(
                                            yearFilter[0].split(",")[0]
                                          )
                                      )
                                    : [],
                                };
                                setUserLists(finalObj);
                                updateCollections(currentUser.uid, finalObj);
                                setCurrentUser({
                                  ...currentUser,
                                  userList: finalObj,
                                });
                              }}
                            />
                          ) : (
                            <BellOutlined
                              style={{
                                fontSize: "0.8em",

                                display: "inline-block",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const finalObj = {
                                  ...userLists,
                                  yearNotifications: userLists.yearNotifications
                                    ? [
                                        ...userLists.yearNotifications,
                                        `${yearFilter[0].split(",")[0]},year,${
                                          yearFilter[0].split(",")[1]
                                        }`,
                                      ]
                                    : [
                                        `${yearFilter[0].split(",")[0]},year,${
                                          yearFilter[0].split(",")[1]
                                        }`,
                                      ],
                                };
                                setUserLists(finalObj);
                                updateCollections(currentUser.uid, finalObj);
                                setCurrentUser({
                                  ...currentUser,
                                  userList: finalObj,
                                });
                              }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </h2>
                    </div>
                    <div className='filterHead statContainer'>
                      <h2>
                        Total Lodges Attended
                        <br />
                        {yearState.lodgesAttended}
                      </h2>
                    </div>
                    <div className='filterHead statContainer'>
                      <h2>
                        Total Patches/Items Made
                        <br />
                        {yearState.patchesAttended}
                      </h2>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div>{renderPatches()[0]}</div>
                <BackTop className='topBtn'>
                  <Button>
                    Back to Top <BiUpArrowCircle />
                  </Button>
                </BackTop>
              </FilterArea>
            ) : (
              <>
                {isLoading ? (
                  <div className='loadingDiv patchLoader'>
                    <Oval color='#443c76' secondaryColor='#d9d9d9' />
                  </div>
                ) : (
                  <div className='patchContent'>
                    {tempPatches?.map((patch, index) => {
                      return (
                        <LazyLoad>
                          <div
                            style={{ position: "relative" }}
                            className='imageContainer'>
                            <Img
                              key={index}
                              src={
                                patch.images?.length > 0
                                  ? patch.images[3]
                                  : patch.image
                              }
                              loading='lazy'
                              alt={patch.comment}
                              preview={{
                                src: patch.image,
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = patch.image;
                              }}
                            />
                            {patch.patchOrderLink ? (
                              <div
                                className='shopContainer'
                                onClick={() =>
                                  window.open(patch.patchOrderLink, "_blank")
                                }>
                                SHOP &nbsp;
                                <ShoppingCartOutlined
                                  style={{
                                    fontSize: "1.5em",
                                    color: "#443c76",
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {currentUser ? (
                              <>
                                {userLists.wants?.includes(patch.id) ? (
                                  ""
                                ) : (
                                  <span
                                    className='collectionIcon'
                                    onClick={() => {
                                      let finalObj = {};
                                      if (
                                        userLists.collections?.includes(
                                          patch.id
                                        )
                                      ) {
                                        finalObj = {
                                          ...userLists,
                                          collections:
                                            userLists.collections?.filter(
                                              (item) => item !== patch.id
                                            ),
                                        };
                                      } else {
                                        finalObj = {
                                          ...userLists,
                                          collections: [
                                            ...userLists.collections,
                                            patch.id,
                                          ],
                                        };
                                      }
                                      setUserLists(finalObj);
                                      updateCollections(
                                        currentUser.uid,
                                        finalObj
                                      );
                                      setCurrentUser({
                                        ...currentUser,
                                        userList: finalObj,
                                      });
                                    }}>
                                    {userLists.collections?.includes(
                                      patch.id
                                    ) ? (
                                      <PlusCircleFilled />
                                    ) : (
                                      <PlusCircleOutlined />
                                    )}
                                  </span>
                                )}

                                {userLists.collections?.includes(patch.id) ? (
                                  ""
                                ) : (
                                  <span
                                    className='wantIcon'
                                    onClick={() => {
                                      let finalObj = {};
                                      if (userLists.wants?.includes(patch.id)) {
                                        finalObj = {
                                          ...userLists,
                                          wants: userLists.wants?.filter(
                                            (item) => item !== patch.id
                                          ),
                                        };
                                      } else {
                                        finalObj = {
                                          ...userLists,
                                          wants: [...userLists.wants, patch.id],
                                        };
                                      }
                                      setUserLists(finalObj);
                                      updateCollections(
                                        currentUser.uid,
                                        finalObj
                                      );
                                      setCurrentUser({
                                        ...currentUser,
                                        userList: finalObj,
                                      });
                                    }}>
                                    {userLists.wants?.includes(patch.id) ? (
                                      <HeartFilled />
                                    ) : (
                                      <HeartOutlined />
                                    )}
                                  </span>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            {yearFilter.length > 1 ? (
                              <p className='text-center mb-0'>
                                <b>{patch.yearName}</b>{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            <p className='text-center mb-0'>
                              <b>{patch.comment}</b>{" "}
                            </p>
                          </div>
                        </LazyLoad>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </PatchesArea>
      </div>
      <Footer />
    </>
  );
};

export default Patches;
