import styled from "styled-components";

export const AboutArea = styled.div`
  .aboutHead {
    background-image: url("/images/noacpatchguidebanner.png");
    width: 100%;
    height: 333px;
    text-align: center;
    div {
      background: linear-gradient(
        90.05deg,
        rgba(36, 0, 255, 0.58) 0.06%,
        rgba(36, 0, 255, 0) 106.91%
      );
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 6.25rem;
        font-weight: 900;
        color: var(--LightColor);
        @media (max-width: 575px) {
          font-size: 3.25rem;
        }
      }
    }
  }
  .aboutContent {
    display: flex;
    .ant-tabs {
      font-size: 1rem;
      .ant-tabs-nav {
        height: 100%;
        background-color: rgba(36, 0, 255, 0.15);
        padding: 2rem 0;
        text-align: center;
        width: 177px;
        @media (max-width: 575px) {
          width: 145px;
        }
        .ant-tabs-tab {
          padding: 0 !important;
          margin: 3rem 0 0;
          display: unset !important;
          color: var(--MainColor);
          &:hover {
            text-decoration: underline;
            font-weight: 700;
          }
          .ant-tabs-tab-btn {
            font-size: 1.5rem;
            @media (max-width: 575px) {
              font-size: 1.25rem;
            }
            color: var(--MainColor);
          }
        }
        .ant-tabs-tab-active {
          text-decoration: underline;
          font-weight: 700;
        }
        .ant-tabs-ink-bar {
          display: none;
        }
      }
      p,
      h1,
      h4,
      ol,
      li {
        color: var(--MainColor);
        font-family: "Raleway", sans-serif;
      }
      .tabHeading {
        font-weight: 700;
        position: relative;
        margin: 2rem 0 4rem;
        display: inline-block;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: var(--MainColor);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .tabPara {
        font-size: 1rem;
        color: var(--MainColor);
        p,
        li {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
        ul {
          margin-bottom: 1rem;
          li {
            margin-bottom: 0;
          }
        }
      }
      .ant-tabs-tabpane {
        padding: 0 5rem;
        @media (max-width: 575px) {
          padding: 0 1.25rem;
        }
      }
      .aboutInfo {
        margin: 5rem 0;
        @media (max-width: 575px) {
          padding: 0 1.25rem !important;
        }
      }
    }
  }
`;
