import styled from "styled-components";

export const LodgeCardArea = styled.div`
  margin: 2rem 1.35rem;
  text-align: center;
  img {
    margin-bottom: 2rem;
  }
  p:nth-child(2) {
    text-decoration: underline;
  }
  p {
    color: var(--MainColor);
  }
  p:last-child {
    margin-bottom: 0;
  }
`;
