import styled from "styled-components";

export const SubmitArea = styled.div`
  padding: 2rem 0 4rem;
  h2,
  h5 {
    font-weight: 600;
  }
  h2,
  h5,
  p {
    color: var(--MainColor);
    text-align: center;
  }
  p {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  .ant-form {
    position: relative;
    padding: 0 4rem;

    @media (max-width: 768px) {
      & {
        padding: 0;
      }
    }
    ${
      "" /* &::before,
    &::after {
      content: "";
      width: 50px;
      height: 95%;
      background-color: var(--MainColor);
      position: absolute;
      bottom: 0;
    } */
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    .ant-form-item {
      margin-bottom: 1rem;

      label {
        font-size: 1.25rem;
        font-weight: 500;
        color: var(--MainColor);
        &::before {
          content: "" !important;
        }
      }
      .ant-form-item-required {
        font-size: 1.25rem;
        font-weight: 500;
        color: var(--MainColor);
        &::before {
          content: "" !important;
        }
      }
      .ant-form-item-control-input {
        .ant-select-selector,.nameInput {
          height: 47px;
          .ant-select-selection-item,.nameInput {
            line-height: 3.3;
          }
        }
        .ant-select-selector,
        textarea,.nameInput {
          background-color: rgba(36, 0, 255, 0.15) !important;
          border: 1px solid var(--MainColor) !important;
          box-shadow: none !important;
        }
      }
      .ant-select {
        position: relative;
        &::after {
          content: "";
          background-image: url(/images/select-dropdown-icon.svg);
          background-repeat: no-repeat;
          width: 30px;
          height: 15px;
          position: absolute;
          right: 1rem;
          top: 50%;
          transition: all 300ms ease-in-out;
          @media (max-width: 575px) {
            right: 0.5rem;
            top: 45%;
          }
        }
        .ant-select-arrow {
          display: none;
        }
      }
      .ant-select-open {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    .ant-upload {
      width: 100%;
      button {
        background-color: rgba(36, 0, 255, 0.15) !important;
        border: 1px solid var(--MainColor) !important;
        box-shadow: none !important;
        color: var(--MainColor);
        width: 100%;
        height: 64px;
        font-size: 1.25rem;
        font-weight: 600;
        @media (max-width: 575px) {
          font-size: 1rem;
        }
      }
    }
    .ant-checkbox-wrapper {
      font-size: 1.25rem;
      font-weight: 600;
      align-items: center;
      margin: 1.5rem 0;
      @media (max-width: 575px) {
        font-size: 1rem;
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          width: 33px;
          height: 33px;
          border: 1px solid var(--MainColor);
          margin: 0 0.5rem;
          @media (max-width: 575px) {
            width: 25px;
            height: 25px;
            margin: 0 0.75rem 0 0;
          }
          &::after {
            top: 42%;
            width: 10px;
            height: 20px;
          }
        }
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--MainColor) !important;
        }
        &::after {
          border: none;
        }
      }
      span:last-child {
        padding: 0;
      }
    }
    .submitBtn {
      button {
        width: 300px;
        height: 64px;
        margin: auto;
        display: block;
        border-radius: 3.125rem;
        background-color: var(--MainColor);
        border-color: var(--MainColor);
        color: var(--LightColor);
        font-weight: 600;
        font-size: 1.25rem;
        box-shadow: none;
        @media (max-width: 575px) {
          font-size: 1rem;
        }
      }
    }
  }
`;
