import styled from "styled-components";

export const CardArea = styled.div`
  background-color: var(--LightColor);
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  @media (max-width: 992px) {
    height: 100%;
    padding: 1rem;
  }
`;
export const CardImage = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;
  background-color: var(--MainColor);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CardHeading = styled.p`
  font-weight: 700;
  position: relative;
  display: inline-block;
  color: var(--MainColor);
  margin: 1rem 0;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--MainColor);
  }
`;
export const CardContent = styled.p`
  width: 100%;
  max-width: 244px;
  margin: 0 auto;
  color: var(--MainColor);
`;
