import styled from "styled-components";
export const ExportArea = styled.div`
  display: flex;
  justify-content: end;
  a {
    text-decoration: none;
  }
  button {
    width: 288px;
    height: 64px;
    font-size: 1.25rem;
    font-weight: 600;
    background-color: var(--MainColor);
    border-color: var(--MainColor);
    color: var(--LightColor);
    box-shadow: none;
    border-radius: 3.125rem;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 17px;
      height: 20px;
    }
  }
`;

export const FilterArea = styled.div`
  .filterHead {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 1rem 1.5rem 2rem;
    h2 {
      font-weight: 700;
      color: var(--MainColor);
    }
    .ant-upload {
      button {
        width: 288px;
        height: 64px;
        font-size: 1.25rem;
        font-weight: 600;
        background-color: var(--MainColor);
        border-color: var(--MainColor);
        color: var(--LightColor);
        box-shadow: none;
        border-radius: 3.125rem;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 17px;
          height: 20px;
        }
      }
    }
  }
  .topBtn {
    width: 252px;
    height: 64px;
    right: 2rem;
    button {
      width: 100%;
      height: 100%;
      font-size: 1.5rem;
      font-weight: 600;
      background-color: var(--MainColor);
      color: var(--LightColor);
      border-color: var(--MainColor);
      border-radius: 3.125rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem;
      box-shadow: 0 10px 10px 0 rgba(36, 0, 255, 0.15);
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;
