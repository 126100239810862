import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 20,
    backgroundColor: 'rgba(36, 0, 255, 0.15)',
    height: 55,
    maxHeight: 55,
    minHeight: 55,
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingLeft: 10,
    paddingRight: 10,
  },
  image: {
    margin: 5,
    minHeight: 100,
    minWidth: 100,
    maxHeight: 100,
    maxWidth: 100,
    objectFit: 'contain',
  },
  subText: {
    fontSize: 10,
    textAlign: 'center',
    width: 100,
  },
})

export default function Index(props) {
  const { patches } = props

  let tempPatches = patches

  tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber)
  return (
    <>
      <View style={styles.section}>
        <Text style={{ color: '#443c76' }}>
          {props.sortNumber} - {props.heading}
        </Text>
      </View>
      <View style={styles.imageSection}>
        {tempPatches?.map((patch, index) => {
          return (
            <>
              <View key={index} style={{ marginBottom: 15 }}>
                <Image
                  src={patch.images?.length > 0 ? patch.images[3] : patch.image}
                  style={styles.image}
                />
                <View>
                  <Text style={styles.subText}>{patch.comment}</Text>
                </View>
              </View>
            </>
          )
        })}
      </View>
    </>
  )
}
