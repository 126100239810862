import styled from "styled-components";

export const UploadArea = styled.div`
  margin: 8rem 1.5rem;
  padding: 0 4rem;
  position: relative;
  &::before,
  &::after {
    content: "";
    width: 50px;
    height: 100%;
    background-color: var(--MainColor);
    position: absolute;
    bottom: 0;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  .ant-upload {
    height: 488px;
    background-color: rgba(36, 0, 255, 0.15);
    border: 1px solid var(--MainColor) !important;
    button {
      width: 412px;
      height: 87px;
      background-color: var(--MainColor);
      border-color: var(--MainColor);
      color: var(--LightColor);
      border-radius: 3.125rem;
      font-weight: 600;
      font-size: 1.5rem;
      margin-bottom: 3rem;
    }
  }
  .ant-checkbox-wrapper {
    font-size: 1.25rem;
    font-weight: 600;
    align-items: center;
    margin: 3rem 0;
    .ant-checkbox {
      .ant-checkbox-inner {
        width: 33px;
        height: 33px;
        border: 1px solid var(--MainColor);
        margin: 0 0.5rem;
        &::after {
          top: 42%;
          width: 10px;
          height: 20px;
        }
      }
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--MainColor) !important;
      }
      &::after {
        border: none;
      }
    }
    span:last-child {
      padding: 0;
    }
  }
  .submitBtn {
    button {
      width: 100%;
      height: 64px;
      border-radius: 3.125rem;
      background-color: var(--MainColor);
      border-color: var(--MainColor);
      color: var(--LightColor);
      font-weight: 600;
      font-size: 1.25rem;
      box-shadow: none;
    }
  }
`;
