import { db, storage, getToken } from '../firebase'
import { message } from 'antd'
import axios from "axios"
import moment from "moment"
const patchesRef = db.collection('patches')
const lodgesRef = db.collection('lodges')
const yearsRef = db.collection('years')
const supporterRef = db.collection('supporterContent')

export const addPatches = (data, setLoading, reset) => {
  db.collection('patches')
    .add({
      comment: data.comment,
      lodge: data.lodge,
      year: data.year,
      status: data.status,
      name: data.name,
      email: data.email,
      dateSubmitted:moment().format("X")
    })
    .then(async (doc) => {

      var ImageUrl = ""
      if (data.image) {
        const resp = await storage.ref(
          `images/${data.year}-${data.lodge}-${data.image.name}`
        )
        const url = await resp.put(data.image).then(() => resp.getDownloadURL())
        ImageUrl = url
        await patchesRef.doc(doc.id).update({
          image: url,
        })
      }

      // axios({
      //   method: 'POST',
      //   url: 'https://us-central1-noac-patch-guide.cloudfunctions.net/default-app/push-notifications',
      //   data: {
      //     lodgeID: data.lodge,
      //     yearID: data.year,
      //     imageURL: ImageUrl,
      //   },
      // }).catch((err) => {
      //   console.log(err)
      // })
      reset(doc.id)
      message.success('Successfuly Created!')
      setLoading(false)
    })
    .catch(function (error) {
      message.error('not Created!')
    })
}

export const updateUser = async (data, collection) => {
  return await db
    .collection('users')
    .doc(data.uid)
    .set({
      wants: [],
      collections: [],
      email: data.email,
      name: data.name,
      token: '',
      lodgeNotifications: [],
      yearNotifications: [],
    })
    .then(async (doc) => {})
    .catch(function (error) {})
}

export const updateToken = async (token, user, restData) => {
  return await db
    .collection('users')
    .doc(user)
    .update({
      token,
    })
    .then(async (doc) => {
      console.log(doc, 'updated')
    })
    .catch(function (error) {})
}

export const updateCollections = async (userId, data) => {
  let tok = await getToken(userId)
  return await db
    .collection('users')
    .doc(userId)
    .update({
      wants: data.wants,
      collections: data.collections,

      lodgeNotifications: data.lodgeNotifications
        ? data.lodgeNotifications
        : [],
      yearNotifications: data.yearNotifications ? data.yearNotifications : [],
    })
    .then(async (doc) => {})
    .catch(function (error) {})
}

export const getCollections = async (userId, setList) => {
  console.log('sending user id', userId)
  return await db
    .collection('users')
    .doc(userId)
    .get()
    .then(async (snapshot) => {
      console.log('got data', snapshot.data())
      if (snapshot.data()) setList(snapshot.data())
    })
    .catch(function (error) {
      console.log(error, 'error is')
    })
}

export const getPatches = (setPatches, years = [], filterList = []) => {
  patchesRef.get().then((snapshot) => {
    const temp = []
    snapshot.forEach((doc) =>
      temp.push({ ...doc.data(), id: doc.id, loaded: true })
    )

    let tempPatches = temp
      .filter((item) => item.status !== 'unapproved')
      .map((item) => {
        return {
          ...item,
          yearName: years.find((ite) => ite.id == item.year).name,
        }
      })

    if (filterList.length > 0) {
      tempPatches = tempPatches.filter((item) => filterList.includes(item.id))
    }
    setPatches(tempPatches)
  })
}

export const getSupporterContent = (setContent) => {
  supporterRef.get().then((snapshot) => {
    const temp = []
    snapshot.forEach((doc) => temp.push({ ...doc.data(), id: doc.id }))
    console.log(temp, 'supporterContent')
    if (temp.length > 0) setContent(temp[0].content)
    else setContent('')
  })
}

export const getLodges = (setLodges) => {
  lodgesRef.get().then((snapshot) => {
    const temp = []
    snapshot.forEach((doc) => temp.push({ ...doc.data(), id: doc.id }))
    setLodges(temp)
  })
}

export const getFilterdLodges = (data, setFilteredLodges) => {
  lodgesRef
    .where('id', 'in', data)
    .get()
    .then((snapshot) => {
      const temp = []
      snapshot.forEach((doc) => temp.push({ ...doc.data(), id: doc.id }))
      setFilteredLodges(temp)
    })
}

export const getYears = (setYears) => {
  yearsRef.get().then((snapshot) => {
    const temp = []
    snapshot.forEach((doc) => temp.push({ ...doc.data(), id: doc.id }))
    setYears(temp)
  })
}
