import styled from "styled-components";

export const FaqInfoArea = styled.div`
  p {
    font-weight: 500;
    font-size: 1.25rem !important;
    width: 100%;
    max-width: 810px;
    margin-bottom: 1.25rem;
  }
  h4 {
    margin-bottom: 1.25rem;
    font-weight: 600;
  }
`;
