import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Space, Select, Input, Image, Popconfirm } from "antd";
import { TbSearch } from "react-icons/tb";
import { IoMdArrowDropright } from "react-icons/io";
import { ImSearch } from "react-icons/im";
import { FilterContentArea, SubItem } from "./filterContent.style";
import { NavLink } from "react-router-dom";
let ebayUrl = "https://www.ebay.com/sch/i.html?_nkw=";
let qaStore = "https://oalodgestore.com/pages/search-results-page?q=";
const FilterContent = (props) => {
  const { patches } = props;
  const [searchVal, setSearchVal] = useState("");
  useEffect(() => {
    if (document.querySelector("#popButton")) {
      document.querySelector("#popButton").click();
    }
  }, []);

  let tempPatches = patches;

  if (searchVal && tempPatches.length) {
    tempPatches = tempPatches.filter((item) => {
      return item?.comment?.toLowerCase().includes(searchVal.toLowerCase());
    });
  }
  tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber);

  return (
    <FilterContentArea>
      <div
        className="contentHead mt-4"
        style={{ backgroundColor: "rgba(36, 0, 255, 0.15)" }}
      >
        <h2 style={{ marginLeft: "10px" }}>
          {props.sortNumber ? `${props.sortNumber} -` : ""} {props.heading}
        </h2>
      </div>
      <div className="contentBody">
        {tempPatches.length ? (
          tempPatches?.map((patch, index) => {
            return (
              <div>
                <Image
                  key={index}
                  src={patch.images?.length > 0 ? patch.images[3] : patch.image}
                  alt={patch.comment}
                  preview={{
                    src: patch.image,
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = patch.image;
                  }}
                />

                {props.yearFilter.length > 1 ? (
                  <p className="text-center mb-0">
                    <b>{patch.yearName}</b>{" "}
                  </p>
                ) : (
                  ""
                )}
                <p className="text-center mb-0">
                  <b>{patch.comment}</b>{" "}
                </p>
              </div>
            );
          })
        ) : (
          <div className="emptyDiv">No Patch Found</div>
        )}
      </div>
    </FilterContentArea>
  );
};

export default FilterContent;
