import React, { useState, useEffect, useMemo, createRef } from 'react'
import { BiUpArrowCircle } from 'react-icons/bi'
import { message, BackTop, Button, Input, Image as Img } from 'antd'

import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import { PatchesArea } from './patches.style'
import { TbSearch } from 'react-icons/tb'
import { getPatches, getLodges, getYears } from '../../utils/api'
import FilterContent from '../../components/filterContent/FilterContent'
import PdfFilterContent from '../../components/PdfFilterContent'
import { FilterArea, ExportArea } from './filter.style'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from '@react-pdf/renderer'
import { useAuthValue } from '../../AuthContext'
import { getCollections } from '../../utils/api'
import YearDropdown from '../../components/dropdowns/yearDropdown/YearDropdown'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
})

const Patches = () => {
  const [patches, setPatches] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [searchVal, setSearchVal] = useState(null)
  const [lodges, setLodges] = useState([])
  const [years, setYears] = useState([])
  const [userLists, setUserLists] = useState({})
  const [pdfBody, setPdfBody] = useState([])
  const [generatePdf, setGeneratePdf] = useState(null)
  const { currentUser } = useAuthValue()
  const [yearFilter, setYearFilter] = useState([])

  const ref = createRef()

  useEffect(() => {
    getLodges(setLodges)
    getYears(setYears)
  }, [])

  useEffect(() => {
    if (currentUser) {
      getCollections(currentUser.uid, setUserLists)
    }
  }, [currentUser])

  useEffect(() => {
    if (userLists.collections && years.length > 0) {
      getPatches(setPatches, years, userLists.collections)
    }
  }, [userLists, years])

  useEffect(() => {
    if (patches.length > 0) {
      setIsLoading(false)
    }
  }, [patches])

  useEffect(() => {
    setPdfBody(
      <Document style={{ width: '100%' }}>
        <Page size='A4' style={styles.page} wrap={true}>
          <View style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Image src={'/NOACPatchGuideTitlePage.jpg'} />
          </View>
          <Text
            style={{
              fontSize: '25px',
              fontWeight: 900,
              color: '#da7a3e',
              textAlign: 'center',
              WebkitTextStrokeWidth: '4px',
              WebkitTextStrokeColor: 'white',
              height: 'auto',
              backgroundColor: 'white',
              borderRadius: '15px',
              width: '300px',
              margin: '-30% auto 30% auto',
              padding: '5px 10px',
            }}
          >
            {yearFilter && yearFilter.length > 0
              ? yearFilter[0].split(',')[0]
              : ''}{' '}
            Collections List for {currentUser?.displayName}
          </Text>
          {renderPatches()[1]}
          <View
            fixed
            style={{
              bottom: 0,
              position: 'absolute',
              margin: 15,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Text style={{ fontSize: 12 }}>
              Collections List for {currentUser?.displayName}
            </Text>
            <Text style={{ fontSize: 12 }}>noacpatchguide.com</Text>
            <Text
              style={{ fontSize: 12, marginRight: 25 }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    )
    setGeneratePdf(false)
  }, [lodges, patches, yearFilter, userLists])

  const renderPatches = () => {
    let tempPatches = patches
    tempPatches = tempPatches.filter((item) =>
      userLists.collections.includes(item.id)
    )

    tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber)
    if (yearFilter.length > 0) {
      let tempYearFilters = yearFilter.map((filtered) => {
        const ID = filtered.split(',')[1]

        return ID
      })

      tempPatches = tempPatches.filter((item) => {
        return tempYearFilters.find((year) => item.year == year)
      })
    }
    if (searchVal) {
      tempPatches = tempPatches.filter((item) => {
        let lodge = lodges.find((lod) => lod.id == item.lodge)
        return (
          item?.comment?.toLowerCase().includes(searchVal.toLowerCase()) ||
          lodge?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
          lodge?.sortingNumber
            ?.toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          lodge?.sortingNumber == searchVal
        )
      })
    }

    var tempInd = 0
    let tempLodges = lodges

    tempLodges = tempLodges.sort((a, b) => {
      if (a.sortingNumber === '') return 1
      if (b.sortingNumber === '') return -1
      return a.sortingNumber - b.sortingNumber
    })

    var pdfArray = []
    var results = tempLodges.map((filtered, index) => {
      if (tempPatches.filter((item) => item.lodge == filtered.id).length > 0) {
        pdfArray.push(
          <PdfFilterContent
            key={tempInd}
            index={tempInd}
            patches={tempPatches.filter((item) => item.lodge == filtered.id)}
            heading={filtered.name}
            sortNumber={filtered.sortingNumber}
            yearFilter={[]}
          />
        )

        tempInd += 1
        return (
          <FilterContent
            key={tempInd}
            index={tempInd}
            patches={tempPatches.filter((item) => item.lodge == filtered.id)}
            heading={filtered.name}
            sortNumber={filtered.sortingNumber}
            lodgeWebsiteLink={filtered.lodgeWebsiteLink}
            yearFilter={[]}
            userLists={userLists}
            setUserLists={setUserLists}
            showBothButtons={true}
            lodgeId={filtered.id}
          />
        )
      } else return false
    })
    results = results.filter((item) => item)

    return [results, pdfArray]
  }

  let tempPatches = patches

  if (searchVal) {
    tempPatches = tempPatches.filter((item) => {
      let lodge = lodges.find((lod) => lod.id == item.lodge)
      return (
        lodge?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
        lodge?.sortingNumber?.toLowerCase().includes(searchVal.toLowerCase()) ||
        lodge?.sortingNumber == searchVal
      )
    })
  }

  tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber)

  return (
    <>
      <Header />

      <div style={{ padding: '4rem 1.5rem', minHeight: '90vh' }}>
        <PatchesArea>
          <div className='patchHead pb-4'>
            <div className='dropDowns'>
              <YearDropdown
                yearFilter={yearFilter}
                setYearFilter={setYearFilter}
              />
            </div>
            <h4
              style={{
                textAlign: 'center',
                fontSize: '3em',
                fontWeight: 'bold',
                color: '#443c76',
              }}
            >
              Your Collection List
            </h4>
            <div className='actionContainer d-flex align-items-center gneneratePdf'>
              {generatePdf ? (
                <ExportArea>
                  <PDFDownloadLink
                    fileName='NOAC 2022 PATCH Guide'
                    document={pdfBody}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <button>Loading Document</button>
                      ) : (
                        <button>Download is Ready</button>
                      )
                    }
                  </PDFDownloadLink>
                </ExportArea>
              ) : (
                <ExportArea
                  onClick={() => {
                    setGeneratePdf(true)
                    message.success(
                      'The PDF is going to take a few seconds to load'
                    )
                  }}
                >
                  <button>Generate PDF</button>
                </ExportArea>
              )}

              <div className='searchInput ' style={{ marginLeft: '15px' }}>
                <Input
                  placeholder='Search'
                  onChange={(e) => setSearchVal(e.target.value)}
                  suffix={<TbSearch />}
                />
              </div>
            </div>
          </div>

          <div ref={ref}>
            <FilterArea>
              <div>{renderPatches()[0]}</div>
              <BackTop className='topBtn'>
                <Button>
                  Back to Top <BiUpArrowCircle />
                </Button>
              </BackTop>
            </FilterArea>
          </div>
        </PatchesArea>
      </div>
      <Footer />
    </>
  )
}

export default Patches
