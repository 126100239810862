import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Space, Table, Tag } from "antd";

export default function index({ isModalOpen, setIsModalOpen }) {
  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
    },
    {
      title: "Requirements",
      dataIndex: "requirements",
      key: "requirements",
    },
  ];

  let data = [
    {
      item: "Accepted File Types",
      requirements: "JPEG (.jpg) and PNG (.png)",
    },
    { item: "Maximum File Size", requirements: "20MB" },
    { item: "Minimum Image Resolution", requirements: "72 DPI" },
    { item: "Minimum Dimensions", requirements: "720 pixels on longest side" },
    { item: "Minimum Image Resolution", requirements: "2400 DPI" },
    { item: "Maximum Dimensions", requirements: "3200 pixels on longest side" },
  ];
  return (
    <Modal
      title="Image Guidelines"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => {
        localStorage.setItem("guidelineModalOpened", "true");
        setIsModalOpen(false);
      }}
      className="imageGuidelineModal"
    >
      <div>
        <h2>
          <b>NOAC Patch Guide Image Do’s and Don’ts</b>{" "}
        </h2>
        <p>
          We wanted to make adding patches to NOAC Patch Guide simple. Just
          review the information below and you are ready to start submitting
          patches.
        </p>
        <p>To make sure your photos can be used review our Do’s and Don’ts:</p>
        <div className="d-flex justify-content-around">
          <div>
            {" "}
            <p>Do:</p>
            <ul>
              <li>Use a scanner or your phone</li>
              <li>Only upload your own images</li> <li>Focus the image</li>{" "}
              <li>Take the photo in a well lit area</li>
              <li>Use a white background if possible</li>{" "}
              <li>Keep the file at the original size</li>{" "}
            </ul>
          </div>
          <div>
            <p>Don't:</p>
            <ul>
              <li>Steal images from Facebook, eBay, PatchVault, etc.</li>
              <li>Use a flash</li> <li>Have anything else in the photo</li>{" "}
              <li>Add any text or graphics</li>
              <li>Enlarge or stretch the photo</li>{" "}
            </ul>
          </div>
        </div>
        <p>
          If you are a Photo Nerd, read on, if not, go take some photos and
          submit them!
        </p>
        <p>
          Now for those photo geeks out there if you want to what type of images
          to submit, read this!
        </p>
        <Table pagination={false} columns={columns} dataSource={data} />

        <p>
          <b>Note</b> : The bigger the dimension of your image, the better the
          opportunity we will have to use that image.
        </p>
      </div>{" "}
    </Modal>
  );
}
