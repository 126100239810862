import './App.css'
import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Supporters from './pages/supporters/Supporters'
import Contact from './pages/contact/Contact'
import SubmitPatch from './pages/submitPatch/SubmitPatch'
import Patches from './pages/patches/Patches'
import Needs from './pages/needs/Needs'
import Collections from './pages/collections/Collections'
import TempImage from './pages/tempImage'
import UploadImage from './pages/uploadImage/UploadImage'
import PrintPdf from './pages/printPage/PrintPage'
import Lodge from './pages/lodge/Lodge'
import Login from './pages/login'
import ForgotPassword from './pages/forgotPassword'
import SignUp from './pages/signup'
import { AuthProvider } from './AuthContext'
import { useState, useEffect } from 'react'
import { auth } from './firebase'

function App() {
  const [currentUser, setCurrentUser] = useState(null)
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
    })
  }, [])
  return (
    <div className='App'>
      <BrowserRouter>
        <AuthProvider value={{ currentUser, setCurrentUser }}>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='submitpatch' element={<SubmitPatch />} />
            <Route path='contact' element={<Contact />} />
            <Route path='about' element={<About />} />
            <Route path='supporters' element={<Supporters />} />
            <Route path='patches' element={<Patches />} />
            <Route path='lodge' element={<Lodge />} />
            <Route path='login' element={<Login />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='needs' element={<Needs />} />
            <Route path='collections' element={<Collections />} />

            {/* <Route path="download-images" element={<TempImage />} /> */}
            <Route path='print-pdf' element={<PrintPdf />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>

      {/* <UploadImage /> */}
      {/* <Patches /> */}
      {/* <Filter /> */}
      {/* <Lodge /> */}
    </div>
  )
}

export default App
