import styled from 'styled-components'

export const PatchesArea = styled.div`
  ${
    '' /* @media print {
    .divFooter {
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  } */
  }

  .mainTitle {
    font-size: 90px;
    font-weight: 900;
    color: #da7a3e;
    top: 70%;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: white;
  }

  .patchContent {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    /* @media (min-width: 1200px) {
      justify-content: space-between;
    } */
    img {
      margin: 2.5rem 1.25rem 1rem 0.25rem;
      min-height: 200px;
      min-width: 200px;
      max-height: 200px;
      max-width: 200px;
      object-fit: contain;
    }
  }
`
