import React from "react";

const TermsInfo = () => {
  return (
    <>
      <h1 className='tabHeading'>Terms of Use</h1>
      <p className='tabPara'>
        This Terms of Use Agreement sets forth the standards of use of the
        website at www.noacpatchguide.com (the “Site”) operated by
        NOAC Patch Guide (“NPG” or “we” or “us”). By using the Site, you (“you”
        or “your” or “User”) agree to these terms and conditions. If you do not
        agree to the terms and conditions of this Agreement, you should
        immediately cease all usage of the Site. We reserve the right, at any
        time, to modify, alter, or update the terms and conditions of this
        agreement without prior notice. Modifications shall become effective
        immediately upon being posted at the Site. Your continued use of the
        Site after amendments are posted constitutes an acknowledgement and
        acceptance of the Agreement and its modifications. Except as provided in
        this paragraph, this Agreement may not be amended.
      </p>
      <ol className='tabPara'>
        <li>
          <strong>Description of Service:</strong> NPG is providing you with
          access to view and reference patches. You must provide (1) all
          equipment necessary for your own Internet connection and (2) provide
          for your access to the Internet, and (3) pay any fees related to such
          connection.
        </li>
        <li>
          <strong>Disclaimer of Warranties:</strong> The Site is provided NPG on
          an “as is” and on an “as available” basis. To the fullest extent
          permitted by applicable law, NPG makes no representations or
          warranties of any kind, express or implied, regarding the use or the
          results of the Site in terms of its correctness, accuracy,
          reliability, or otherwise. NPG shall have no liability for any
          interruptions in the use of the Site. NPG disclaims all warranties
          with regard to the information provided, including the implied
          warranties of merchantability and fitness for a particular purpose,
          and non-infringement. Some jurisdictions do not allow the exclusion of
          implied warranties; therefore, the above-referenced exclusion may be
          inapplicable.
        </li>
        <li>
          <strong>Limitation of Liability:</strong> NPG SHALL NOT BE LIABLE FOR
          ANY DAMAGES WHATSOEVER, AND IN PARTICULAR NPG SHALL NOT BE LIABLE FOR
          ANY SPECIAL, INDIRECT, CONSEQUENTIAL, OR INCIDENTAL DAMAGES, OR
          DAMAGES FOR LOST PROFITS, LOSS OF REVENUE, OR LOSS OF USE, ARISING OUT
          OF OR RELATED TO THE SITE OR THE INFORMATION CONTAINED IN IT, WHETHER
          SUCH DAMAGES ARISE IN CONTRACT, NEGLIGENCE, TORT, UNTER STATUTE, IN
          EQUITY, AT LAW, OR OTHERWISE, EVEN IF NPG HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW FOR THE
          LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES; THEREFORE SOME OF THE ABOVE LIMITATIONS MAY BE INAPPLICABLE.
        </li>
        <li>
          <strong>Indemnification:</strong> You agree to indemnify and hold NPG,
          its parents, subsidiaries, affiliates, officers and employees,
          harmless from any claim or demand, including attorneys’ fees and
          costs, made by any third party due to or arising out of Your use of
          the Site, the violation of this Agreement, or violation of any law by
          User.
        </li>
        <li>
          <strong>Modifications and Interruption to Site:</strong> NPG reserves
          the right to modify or discontinue the Site with or without notice to
          the User. NPG shall not be liable to You, or any third party should
          NPG exercise its right to modify or discontinue the Site. User
          acknowledges and accepts that NPG does not guarantee continuous,
          uninterrupted or secure access to the Site, and operation of the Site
          may be interfered with or adversely affected by numerous factors or
          circumstances outside of NPG’s control.
        </li>
        <li>
          <strong>
            Disclaimer Regarding Accuracy of Lodge or Patch Information:
          </strong>{" "}
          Specifications about and other information regarding Lodges or Patches
          on the Site have been provided by individual Users and is not verified
          by NPG. While NPG makes every effort to ensure that the information on
          the Site is accurate, we can make no representations or warranties as
          to the accuracy or reliability of any information provided on the
          Site.
          <span className='mt-2 d-block'>
            NPG makes no warranties or representations whatsoever with regard to
            any patch provided by any User, and you acknowledge that any
            reliance on representations and warranties provided by any User
            shall be at your own risk.
          </span>
        </li>
        <li>
          <strong>Governing Jurisdiction of the Courts of Utah:</strong> The
          site is operated and provided in the State of Utah. As such, we are
          subject to the laws of the State of Utah, and such laws will govern
          this Agreement, without giving effect to any choice of law rules. We
          make no representation that the Site or other services are
          appropriate, legal or available for use in other locations.
          Accordingly, if you choose to access the Site you agree to do so
          subject to the internal laws of the State of Utah.
        </li>
        <li>
          <strong>Compliance with Laws:</strong> User assumes all knowledge of
          applicable law and is responsible for compliance with any such laws.
          User may not use the Site in any way that violates applicable state,
          federal, or international laws, regulations or other government
          requirements. User further agrees not to transmit any material that
          encourages conduct that could constitute a criminal offense, give rise
          to civil liability or otherwise violate any applicable local, state,
          national, or international law or regulation.
        </li>
        <li>
          <strong>Copyright and Trademark Information:</strong> By submitting to
          the Site any images and content (“Images”), You grant to NPG the
          worldwide, perpetual, irrevocable, royalty-free, sublicensable right
          and license to maintain and distribute such Images and content via the
          site and other communications related to the functions and purpose of
          the Site. To the extent that you do not own all rights in and to the
          Images or that NPG’s use of any of the Images as permitted under this
          Agreement infringes or otherwise violates the rights of any third
          party, you agree to indemnify and hold NPG harmless from any claims or
          demands, including attorneys’ fees and costs, incurred by NPG with
          respect thereto.
          <span className='mt-2 d-block'>
            All images on the Site are either owned or licensed by NPG and may
            not be reproduced or used in any manner without the express written
            permission of NPG and, if appropriate, the relevant Lodge. Copyright
            © 2024 NOAC Patch Guide. All rights reserved. All patch artwork is
            owned solely by the individual Lodges represented here and are
            subject to United States and International copyright laws.
          </span>
          <span className='mt-2 d-block'>
            NPG, the Site logo NOAC Patch Guide are trademarks owned by NPG and
            may not be used without prior written permission of NPG.
          </span>
        </li>
        <li>
          <strong>Notification of Claimed Copyright Infringement:</strong> If
          you believe that any works on the Site infringe your copyright rights,
          please provide NPG copyright agent with information as required by
          Section 512(c) of the U.S. Copyright Act. NPG’s Copyright Agent for
          notice of claims of copyright infringement on its site can be reached
          as follows:
          <span className='mt-2 d-block'>
            NOAC Patch Guide
            <br />
            PO Box 24
            <br />
            American Fork, UT 84003
          </span>
        </li>
        <li>
          <strong>Botnets:</strong> NPG retains the right, at our sole
          discretion, to terminate any accounts involved with botnets and
          related activities. If any hostnames are used as command and control
          points for botnets, NPG reserves the right to direct the involved
          hostnames to a honeypot, loopback address, logging facility, or any
          other destination at NPG discretion.
        </li>
        <li>
          <strong>Other Terms:</strong> If any provision of this Agreement shall
          be unlawful, void or unenforceable for any reason, the other
          provisions (and any partially-enforceable provision) shall not be
          affected thereby and shall remain valid and enforceable to the maximum
          possible extent. You agree that this Agreement and any other
          agreements referenced herein may be assigned by NPG in its sole
          discretion, to a third party in the event of a merger or acquisition.
          This Agreement shall apply in addition to, and shall not be superseded
          by, any other written agreement between us in relation to your
          participation as a User. User agrees that by accepting this Agreement,
          User is consenting to the use and disclosure of his or her personally
          identifiable information and other practices described in the NPG
          Privacy Statement.
        </li>
      </ol>
    </>
  );
};

export default TermsInfo;
