import React, { useState, useEffect } from 'react'
import { Dropdown, Menu, Space, Select, Input, Image, Popconfirm } from 'antd'
import { TbSearch } from 'react-icons/tb'
import { IoMdArrowDropright } from 'react-icons/io'
import { ImSearch } from 'react-icons/im'
import { FilterContentArea, SubItem } from './filterContent.style'
import { NavLink } from 'react-router-dom'
import { ShoppingCartOutlined, LinkOutlined } from '@ant-design/icons'
import { useAuthValue } from '../../AuthContext'
import { getCollections, updateCollections } from '../../utils/api'
import {
  HeartOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  HeartFilled,
  BellOutlined,
  BellFilled,
} from '@ant-design/icons'

let ebayUrl = 'https://www.ebay.com/sch/i.html?_nkw='
let qaStore = 'https://oalodgestore.com/pages/search-results-page?q='
const FilterContent = (props) => {
  const { patches } = props
  const [searchVal, setSearchVal] = useState('')
  const { currentUser, setCurrentUser } = useAuthValue()
  const { userLists, setUserLists } = props
  useEffect(() => {
    if (document.querySelector('#popButton')) {
      document.querySelector('#popButton').click()
    }
  }, [])

  const menu = () => {
    let menuItems = [
      {
        key: '1',
        label: (
          <SubItem
            onClick={() => {
              let newUrl = `${ebayUrl}${props.sortingNumber ? 'Lodge' : ''} ${
                props.sortNumber
              } ${props.heading} NOAC ${props.yearFilter
                .map((item) => item.split(',')[0])
                .join(',')}`
              window.open(newUrl, '__blank')
            }}
          >
            <ImSearch />
            <span>eBay</span>
          </SubItem>
        ),
      },
      {
        key: '2',
        label: (
          <SubItem
            onClick={() => {
              let newUrl = `${qaStore}Lodge ${props.sortNumber} ${
                props.heading
              } NOAC ${props.yearFilter
                .map((item) => item.split(',')[0])
                .join(',')}`
              window.open(newUrl, '__blank')
            }}
          >
            <ImSearch />
            <span>OA Lodge Store</span>
          </SubItem>
        ),
      },
      {
        key: '3',
        label: (
          <NavLink
            className='submitLink'
            to={`/submitPatch?lodge=${props.heading}${
              props.yearFilter.length > 0
                ? `&year=${props.yearFilter[0].split(',')[0]}`
                : ''
            }`}
          >
            <img src='/images/submit-icon.svg' alt='click here' />
            <span>Submit New Patch</span>
          </NavLink>
        ),
      },
      {
        key: '4',
        label: (
          <SubItem
            href={`mailto:hello@noacpatchguide.com?subject=Report Error for Lodge ${props.sortNumber} - ${props.heading}`}
          >
            <img src='/images/report-icon.svg' alt='click here' />
            <span>Report Error</span>
          </SubItem>
        ),
      },
    ]
    if (props.lodgeWebsiteLink) {
      menuItems.unshift({
        key: '5',
        label: (
          <SubItem
            onClick={() => {
              if (props.lodgeWebsiteLink)
                window.open(props.lodgeWebsiteLink, '_blank')
            }}
          >
            <LinkOutlined />
            <span>Visit Lodge Website</span>
          </SubItem>
        ),
      })
    }
    return <Menu items={menuItems} />
  }

  let tempPatches = patches

  if (searchVal && tempPatches.length) {
    tempPatches = tempPatches.filter((item) => {
      return item?.comment?.toLowerCase().includes(searchVal.toLowerCase())
    })
  }
  tempPatches = tempPatches.sort((a, b) => a.sortingNumber - b.sortingNumber)
  return (
    <FilterContentArea>
      {props.index == 0 ? (
        <Popconfirm
          placement='topLeft'
          title={'You can open the search menu by clicking on these 3 dots'}
          okText='Ok'
          showCancel={false}
        >
          <button id='popButton' style={{ opacity: 0 }}>
            TL
          </button>
        </Popconfirm>
      ) : (
        ''
      )}
      <div className='contentHead mt-4'>
        <Dropdown
          overlay={menu()}
          trigger={['click']}
          placement='bottomLeft'
          className='menuIcon'
          onVisibleChange={() => {
            setSearchVal('')
          }}
          arrow={{
            pointAtCenter: true,
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
            </Space>
          </a>
        </Dropdown>
        <h2
          style={{
            marginLeft: '20px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            if (props.lodgeWebsiteLink)
              window.open(props.lodgeWebsiteLink, '_blank')
          }}
        >
          {props.sortNumber ? `${props.sortNumber} -` : ''} {props.heading}{' '}
          {props.lodgeWebsiteLink ? (
            <LinkOutlined
              className={`linkIcon${props.sortNumber}`}
              style={{
                fontSize: '0.8em',
                marginLeft: '5px',
                display: 'inline-block',
              }}
            />
          ) : (
            ''
          )}
          {currentUser ? (
            userLists.lodgeNotifications?.find((item) =>
              item.includes(props.lodgeId)
            ) ? (
              <BellFilled
                style={{
                  fontSize: '0.8em',
                  marginLeft: '5px',
                  display: 'inline-block',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const finalObj = {
                    ...userLists,
                    lodgeNotifications: userLists.lodgeNotifications
                      ? userLists.lodgeNotifications.filter(
                          (item) => !item.includes(props.lodgeId)
                        )
                      : [],
                  }
                  setUserLists(finalObj)
                  updateCollections(currentUser.uid, finalObj)
                  setCurrentUser({ ...currentUser, userList: finalObj })
                }}
              />
            ) : (
              <BellOutlined
                style={{
                  fontSize: '0.8em',
                  marginLeft: '5px',
                  display: 'inline-block',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  const finalObj = {
                    ...userLists,
                    lodgeNotifications: userLists.lodgeNotifications
                      ? [
                          ...userLists.lodgeNotifications,
                          `${props.lodgeId},lodge,${props.heading}`,
                        ]
                      : [`${props.lodgeId},lodge,${props.heading}`],
                  }
                  setUserLists(finalObj)

                  updateCollections(currentUser.uid, finalObj)
                  setCurrentUser({ ...currentUser, userList: finalObj })
                }}
              />
            )
          ) : (
            ''
          )}
        </h2>
      </div>
      <div className='contentBody'>
        {tempPatches.length ? (
          tempPatches?.map((patch, index) => {
            return (
              <div style={{ position: 'relative' }} className='imageContainer'>
                <Image
                  className='previewImage'
                  key={index}
                  src={patch.images?.length > 0 ? patch.images[3] : patch.image}
                  alt={patch.comment}
                  preview={{
                    src: patch.image,
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = patch.image
                  }}
                  // onError={() => {
                  //   console.log(patch, "has image error");
                  // }}
                />
                {patch.patchOrderLink ? (
                  <div
                    className='shopContainer shopButton'
                    onClick={() => window.open(patch.patchOrderLink, '_blank')}
                  >
                    SHOP &nbsp;
                    <ShoppingCartOutlined
                      style={{ fontSize: '1.5em', color: '#443c76' }}
                    />
                  </div>
                ) : (
                  ''
                )}

                {currentUser ? (
                  <>
                    {userLists.wants?.includes(patch.id) &&
                    !props.showBothButtons ? (
                      ''
                    ) : (
                      <span
                        className='collectionIcon'
                        onClick={() => {
                          let finalObj = {}
                          if (userLists.collections?.includes(patch.id)) {
                            finalObj = {
                              ...userLists,
                              collections: userLists.collections?.filter(
                                (item) => item !== patch.id
                              ),
                            }
                          } else {
                            finalObj = {
                              ...userLists,
                              collections: [...userLists.collections, patch.id],
                            }
                            if (props.showBothButtons) {
                              finalObj = {
                                ...finalObj,
                                wants: finalObj.wants.filter(
                                  (item) => item !== patch.id
                                ),
                              }
                            }
                          }
                          setUserLists(finalObj)
                          updateCollections(currentUser.uid, finalObj)
                          setCurrentUser({ ...currentUser, userList: finalObj })
                        }}
                      >
                        {userLists.collections?.includes(patch.id) ? (
                          <PlusCircleFilled />
                        ) : (
                          <PlusCircleOutlined />
                        )}
                      </span>
                    )}

                    {userLists.collections?.includes(patch.id) &&
                    !props.showBothButtons ? (
                      ''
                    ) : (
                      <span
                        className='wantIcon'
                        onClick={() => {
                          let finalObj = {}
                          if (userLists.wants?.includes(patch.id)) {
                            finalObj = {
                              ...userLists,
                              wants: userLists.wants?.filter(
                                (item) => item !== patch.id
                              ),
                            }
                          } else {
                            finalObj = {
                              ...userLists,
                              wants: [...userLists.wants, patch.id],
                            }
                            if (props.showBothButtons) {
                              finalObj = {
                                ...finalObj,
                                collections: finalObj.collections?.filter(
                                  (item) => item !== patch.id
                                ),
                              }
                            }
                          }
                          setUserLists(finalObj)
                          updateCollections(currentUser.uid, finalObj)
                          setCurrentUser({ ...currentUser, userList: finalObj })
                        }}
                      >
                        {userLists.wants?.includes(patch.id) ? (
                          <HeartFilled />
                        ) : (
                          <HeartOutlined />
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  ''
                )}
                {props.yearFilter.length > 1 ? (
                  <p className='text-center mb-0'>
                    <b>{patch.yearName}</b>{' '}
                  </p>
                ) : (
                  ''
                )}
                <p className='text-center mb-0'>
                  <b>{patch.comment}</b>{' '}
                </p>
              </div>
            )
          })
        ) : (
          <div className='emptyDiv'>No Patch Found</div>
        )}
      </div>
    </FilterContentArea>
  )
}

export default FilterContent
