import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Upload, Checkbox } from "antd";
import Header from "../../components/header/Header";
import { SubmitArea } from "./submitPatch.style";
import Footer from "../../components/footer/Footer";
import { getLodges, getYears, addPatches } from "../../utils/api";
import axios from "axios";
import ImageGuidelineModal from "../../components/imageGuideline";

function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}
const SubmitPatch = () => {
  const [loading, setLoading] = useState(false);
  const [lodges, setLodges] = useState([]);
  const [years, setYears] = useState([]);
  const [image, setImage] = useState();
  const [textAreaValue, setTextAreaValue] = useState("");
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Option } = Select;
  const { TextArea } = Input;

  const reset = (docId) => {
    axios.get(
      `https://us-central1-noac-patch-guide.cloudfunctions.net/default/patchImages/${docId}`
    );
    form.resetFields();
  };

  const handleFinish = (value) => {
    setLoading(true);
    addPatches(
      {
        image: image,
        comment: value.comment ? value.comment : "",
        lodge: value.lodge,
        year: value.year,
        status: "unapproved",
        name: value.name,
        email: value.email,
      },
      setLoading,
      reset
    );

    
  };

  useEffect(() => {
    if (lodges.length > 0) {
      var params = {};
      let lodgeId = lodges[0].id;
      if (window.location.search) {
        params = parseQuery(window.location.search);
        if (params.lodge)
          lodgeId = lodges.find((item) => item.name == params.lodge).id;
      }

      form.resetFields();
      form.setFieldsValue({
        lodge: lodgeId,
      });
      if (years.length > 0) {
        let yearId = "";

        if (params.year)
          yearId = years.find((item) => item.name == params.year).id;

        form.setFieldsValue({
          year: yearId,
        });
      }
    }
  }, [lodges]);
  useEffect(() => {
    if (years.length > 0) {
      let yearId = "";
      var params = {};
      if (window.location.search) {
        params = parseQuery(window.location.search);
        if (params.year)
          yearId = years.find((item) => item.name == params.year).id;
      }
      form.resetFields();
      form.setFieldsValue({
        year: yearId,
      });

      if (lodges.length > 0) {
        let lodgeId = lodges[0].id;
        if (params.lodge)
          lodgeId = lodges.find((item) => item.name == params.lodge).id;

        form.setFieldsValue({
          lodge: lodgeId,
        });
      }
    }
  }, [years]);

  useEffect(() => {
    getLodges(setLodges);
    getYears(setYears);

    if (!localStorage.getItem("guidelineModalOpened")) setIsModalOpen(true);
  }, []);

  const props = {
    name: "image",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    listType: "picture",

    onChange(info) {
      // setImage(info.file.originFileObj);
    },
  };

  return (
    <>
      <Header />
      <div style={{ padding: "0 1.5rem" }}>
        <SubmitArea>
          <ImageGuidelineModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
          <h2>Submit a patch by completing this form below.</h2>
          <h5>Help grow the guide by submitting NOAC Patches that you have.</h5>
          <p>
            Just complete the information below and we will review the patch and
            information. Once we approve it, It will be posted to the guide.
          </p>
          <Form
            name="contactForm"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={handleFinish}
            form={form}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input className="nameInput" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input className="nameInput" />
            </Form.Item>

            <Form.Item
              label="NOAC Year"
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please input your noac year!",
                },
              ]}
            >
              <Select
                showSearch
                value={years.length > 0 ? years[0].id : ""}
                placeholder=""
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {years.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Lodge"
              name="lodge"
              rules={[
                {
                  required: true,
                  message: "Please input your lodge!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder=""
                defaultValue={lodges.length > 0 ? lodges[0].id : ""}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.children.find((item) => {
                    return String(item)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  });
                }}
              >
                {lodges
                  .sort((a, b) => a.sortingNumber - b.sortingNumber)
                  .map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.sortingNumber} - {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Patch Comment" name="comment">
              <TextArea
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
                autoSize={{
                  minRows: 8,
                  maxRows: 8,
                }}
              />
            </Form.Item>
            <p
              style={{
                cursor: "pointer",
                textAlign: "left",
                margin: 0,
                fontWeight: "bold",
              }}
              onClick={() => setIsModalOpen(true)}
            >
              Check Image Do's and Don'ts
            </p>
            <Form.Item
              label=""
              name="upload"
              rules={[
                {
                  required: true,
                  message: "Please upload patch image!",
                },
              ]}
            >
              <Upload
                {...props}
                beforeUpload={(e) => {
                  setImage(e);
                  /* update state here */
                  return false;
                }}
              >
                <Button>Upload Patch Image</Button>
              </Upload>
            </Form.Item>

            <Checkbox required>
              I agree to the terms and conditions of
              <strong> NOAC Patch Guide.</strong>
            </Checkbox>
            <div className="submitBtn">
              <Button htmlType="submit" loading={loading}>
                Submit for Review
              </Button>
            </div>
          </Form>
        </SubmitArea>
      </div>
      <Footer />
    </>
  );
};

export default SubmitPatch;
