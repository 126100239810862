import React from 'react'

const AboutInfo = () => {
  return (
    <>
      <p>
        NOAC Patch Guide started as a discussion between a few collectors (Adam
        McDaniel, Wiatt Williamson, and Russ Baker) after NOAC 2022. We
        discussed the great work that was done by a fellow collector (Brian
        Ives) to have a PDF guide that showed people attending NOAC and other
        collectors the different patches issued by Lodges.
      </p>
      <p>
        We discussed while that was extremely valuable it didn’t allow for any
        updates or changes that take place. It also didn’t keep the data in a
        place that can be accessible for the future for everyone. Lastly, it
        also didn’t allow for a community aspect of patch collecting which
        allows all collectors to help keep collecting resources up to date.
      </p>
      <p>
        So, the idea was hatched for putting together a mobile optimized web app
        that allowed people to view patches by Lodge or by Year so they could
        see all the NOAC patches issued. The web app would also allow collectors
        to add patches to the app so each person could help improve the guide.
      </p>
      <p>
        Thanks to Russ Baker, Wiatt Williamson, and Brian Ives for their
        feedback on the site and providing a number of the patches from their
        collection to fill out the site. We really appreciate their help.{' '}
      </p>
    </>
  )
}

export default AboutInfo
