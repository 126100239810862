import React from 'react'
import { Foot } from './footer.style'

const Footer = () => {
  return (
    <>
      <Foot>
        <div>
          <p>
            © NOAC Patch Guide 2024. All rights reserved. Photos and Content are
            © their respective owners. Personal, non-commercial use permitted.
          </p>
        </div>
        <div>
          <a href='#'>Terms of Use</a>
          <a href='#'>Privacy Policy</a>
        </div>
      </Foot>
    </>
  )
}

export default Footer
