import React from "react";
import {
  CardArea,
  CardContent,
  CardHeading,
  CardImage,
} from "./featureCard.style";

const FeatureCard = (props) => {
  const data = props.data;
  return (
    <>
      <CardArea>
        <div>
          <CardImage>
            <img src={data.image} alt="click here" />
          </CardImage>
          <CardHeading>{data.heading}</CardHeading>
          <CardContent>{data.text}</CardContent>
        </div>
      </CardArea>
    </>
  );
};

export default FeatureCard;
